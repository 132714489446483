import React from "react";

import DocumentationPageLayout from "@src/components/DocumentationPageLayout";

function PremiumFeatureTerms({ pageContext }) {
  return (
    <DocumentationPageLayout
      content={pageContext.premiumFeaturesTerms}
      title="Premium Features Terms"
      seoTitle="page_titles.premium-features-terms"
      seoDescription="page_descriptions.premium-features-terms"
      type="premium"
    />
  );
}

export default PremiumFeatureTerms;
